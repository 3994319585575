// Users.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Users.css";

const apiUrl = "https://bakubike.duckdns.org"; // Replace with your backend API URL

const Users = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingUserId, setEditingUserId] = useState(null);
    const [editedUser, setEditedUser] = useState({});

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/admin/get-users`);
                setUsers(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching users:", err);
                setError("Failed to fetch users. Please try again later.");
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleInputChange = (e, field) => {
        const value = field === "balance" ? parseFloat(e.target.value).toFixed(2) : e.target.value;
        setEditedUser({ ...editedUser, [field]: value });
    };

    const handleEdit = (user) => {
        setEditingUserId(user._id);
        setEditedUser({ ...user });
    };

    const handleUpdate = async () => {
        try {
            const { _id, ...userData } = editedUser;
            if (userData.balance) {
                userData.balance = parseFloat(userData.balance).toFixed(2);
            }

            await axios.put(`${apiUrl}/api/admin/update-user/${editingUserId}`, userData);
            setUsers(users.map((user) => (user._id === editingUserId ? { ...editedUser, balance: parseFloat(editedUser.balance).toFixed(2) } : user)));
            setEditingUserId(null);
        } catch (error) {
            console.error("Error updating user:", error);
            alert("Failed to update user. Please try again.");
        }
    };

    if (loading) {
        return <p>Loading users...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="users-table-container">
            <h1 className="title">Users</h1>
            <table className="users-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>Email</th>
                        <th>Nationality</th>
                        <th>Birthday</th>
                        <th>Identifier Type</th>
                        <th>Balance</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user._id}>
                            <td>
                                {editingUserId === user._id ? (
                                    <input
                                        type="text"
                                        value={editedUser.name}
                                        onChange={(e) => handleInputChange(e, "name")}
                                    />
                                ) : (
                                    user.name
                                )}
                            </td>
                            <td>
                                {editingUserId === user._id ? (
                                    <input
                                        type="text"
                                        value={editedUser.surname}
                                        onChange={(e) => handleInputChange(e, "surname")}
                                    />
                                ) : (
                                    user.surname
                                )}
                            </td>
                            <td>
                                {editingUserId === user._id ? (
                                    <input
                                        type="email"
                                        value={editedUser.email}
                                        onChange={(e) => handleInputChange(e, "email")}
                                    />
                                ) : (
                                    user.email
                                )}
                            </td>
                            <td>
                                {editingUserId === user._id ? (
                                    <input
                                        type="text"
                                        value={editedUser.nationality}
                                        onChange={(e) => handleInputChange(e, "nationality")}
                                    />
                                ) : (
                                    user.nationality
                                )}
                            </td>
                            <td>
                                {editingUserId === user._id ? (
                                    <input
                                        type="date"
                                        value={editedUser.birthday ? editedUser.birthday.split("T")[0] : ""}
                                        onChange={(e) => handleInputChange(e, "birthday")}
                                    />
                                ) : (
                                    new Date(user.birthday).toLocaleDateString()
                                )}
                            </td>
                            <td>{user.identifier_type}</td>
                            <td>
                                {editingUserId === user._id ? (
                                    <input
                                        type="number"
                                        step="0.01"
                                        value={
                                            editedUser.balance && editedUser.balance.$numberDecimal
                                                ? parseFloat(editedUser.balance.$numberDecimal)
                                                : parseFloat(editedUser.balance) || 0
                                        }
                                        onChange={(e) => handleInputChange(e, "balance")}
                                    />
                                ) : user.balance && user.balance.$numberDecimal
                                    ? parseFloat(user.balance.$numberDecimal).toFixed(2)
                                    : parseFloat(user.balance).toFixed(2)}
                            </td>
                            <td>
                                {editingUserId === user._id ? (
                                    <button onClick={handleUpdate}>Update</button>
                                ) : (
                                    <button onClick={() => handleEdit(user)}>Edit</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Users;
