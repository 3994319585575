import React, { useState } from "react";
import axios from "axios";
import "./AppStyles.css"; // Unified CSS for styling

const apiUrl = "https://bakubike.duckdns.org"; // Update with the actual backend URL

const GenerateQR = () => {
  const [activeTab, setActiveTab] = useState("individual"); // State to track the active tab
  const [bikeNumber, setBikeNumber] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // State for batch generation
  const [startNumber, setStartNumber] = useState("");
  const [endNumber, setEndNumber] = useState("");
  const [batchQrCodes, setBatchQrCodes] = useState([]);
  const [batchLoading, setBatchLoading] = useState(false);
  const [batchErrorMessage, setBatchErrorMessage] = useState("");

  // Function to generate individual QR code
  const handleGenerateQR = async () => {
    setErrorMessage("");

    if (!bikeNumber || parseInt(bikeNumber) < 1 || parseInt(bikeNumber) > 9999) {
      setErrorMessage("Please enter a valid number between 0001 and 9999");
      return;
    }

    const formattedBikeId = `BB${String(bikeNumber).padStart(4, "0")}`;

    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/api/auth/generate-qr`, { bikeId: formattedBikeId });

      if (response.data.message === "QR code already exists") {
        alert(`A QR code for Bike ID ${formattedBikeId} already exists.`);
        setQrCode(response.data.qrCode); // Display the existing QR code
      } else if (response.data.qrCode) {
        setQrCode(response.data.qrCode); // Display the newly generated QR code
      } else {
        setErrorMessage("Unexpected response format");
      }
    } catch (error) {
      console.error("Error generating QR code:", error);
      setErrorMessage("Failed to generate QR code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Function to generate batch QR codes
  const handleBatchGenerateQR = async () => {
    setBatchErrorMessage("");

    if (!startNumber || !endNumber || parseInt(startNumber) < 1 || parseInt(endNumber) > 9999 || parseInt(startNumber) > parseInt(endNumber)) {
      setBatchErrorMessage("Please enter a valid range between 0001 and 9999");
      return;
    }

    try {
      setBatchLoading(true);
      const response = await axios.post(`${apiUrl}/api/auth/generate-batch-qr`, {
        startNumber: parseInt(startNumber),
        endNumber: parseInt(endNumber),
      });

      setBatchQrCodes(response.data.qrCodes);
    } catch (error) {
      console.error("Error generating batch QR codes:", error);
      setBatchErrorMessage("Failed to generate batch QR codes. Please try again.");
    } finally {
      setBatchLoading(false);
    }
  };

  // Ensure the input does not exceed 4 digits
  const handleInputChange = (e, setter) => {
    const value = e.target.value;

    // Ensure value is a number and does not exceed 4 digits
    if (value.length <= 4) {
      setter(value);
    }
  };

  return (
    <div className="container">
      {/* Navigation Bar for Selecting Individual or Batch */}
      <div className="nav">
        <button
          className={`nav-button ${activeTab === "individual" ? "active" : ""}`}
          onClick={() => setActiveTab("individual")}
        >
          Individual QR Generation
        </button>
        <button
          className={`nav-button ${activeTab === "batch" ? "active" : ""}`}
          onClick={() => setActiveTab("batch")}
        >
          Batch QR Generation
        </button>
      </div>

      {/* Content Based on Active Tab */}
      {activeTab === "individual" && (
        <div>
          <h1 className="title">Individual QR Generation</h1>
          <div className="input-container">
            <label className="label">Bike Number (BBXXXX)</label>
            <div className="input-group">
              <input
                type="number"
                placeholder="Enter a number from 0001 to 9999"
                value={bikeNumber}
                onChange={(e) => handleInputChange(e, setBikeNumber)}
                className="input"
                min={1}
                max={9999}
              />
              <button onClick={handleGenerateQR} className="button" disabled={loading}>
                {loading ? "Generating..." : "Generate QR"}
              </button>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {qrCode && (
              <div className="qr-code-container">
                <h2 className="qr-code-title">QR Code for Bike BB{String(bikeNumber).padStart(4, "0")}</h2>
                <img src={qrCode} alt={`QR Code for bike BB${String(bikeNumber).padStart(4, "0")}`} className="qr-code-image" />
              </div>
            )}
          </div>
        </div>
      )}

      {activeTab === "batch" && (
        <div>
          <h1 className="title">Batch QR Generation (Range)</h1>
          <div className="input-container">
            <label className="label">Start Number (0001 to 9999)</label>
            <input
              type="number"
              placeholder="Start Number"
              value={startNumber}
              onChange={(e) => handleInputChange(e, setStartNumber)}
              className="input"
              min={1}
              max={9999}
            />
            <label className="label">End Number (0001 to 9999)</label>
            <input
              type="number"
              placeholder="End Number"
              value={endNumber}
              onChange={(e) => handleInputChange(e, setEndNumber)}
              className="input"
              min={1}
              max={9999}
            />
          </div>
          {batchErrorMessage && <p className="error-message">{batchErrorMessage}</p>}
          <button onClick={handleBatchGenerateQR} className="button" disabled={batchLoading}>
            {batchLoading ? "Generating QR Codes..." : "Generate QR Codes"}
          </button>

          <div className="qr-code-container">
            {batchQrCodes.map((qr, index) => (
              <div key={index} className="qr-code-item">
                <h2>QR Code for Bike {qr.bikeId}</h2>
                <img src={qr.qrCode} alt={`QR Code for ${qr.bikeId}`} className="qr-code-image" />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GenerateQR;
