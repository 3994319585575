import React, { useState } from "react";
// import logo from "./assets/logo.png";
import sectionImage from "./assets/background.png"; // Replace with the actual path

import Navbar from './Navbar';  // Import Navbar
import Footer from './Footer';  // Import Footer

// Import your guide images
import step1 from "./assets/1.png";
import step2 from "./assets/2.png";
import step3 from "./assets/3.png";
import step4 from "./assets/4.png";
import step5 from "./assets/5.png";
import step6 from "./assets/6.png";
import step7 from "./assets/7.png";
import step8 from "./assets/8.png";

const languages = {
  az: {
    title: "Baku Bike",
    description: "Bakıda ən yaxşı velosiped icarəsi təcrübəsini yaşayın.",
    howTo: "Necə Başlamaq Olar?",
    testimonials: "Rəylər",
    download: "Tətbiqi Yüklə",
  },
  en: {
    title: "Baku Bike",
    description: "Experience the best bike rental service in Baku.",
    about: "About Us",
    howTo: "How to Get Started?",
    testimonials: "Testimonials",
    download: "Download the App",
  },
  ru: {
    title: "Baku Bike",
    description: "Получите лучший опыт аренды велосипедов в Баку.",
    howTo: "Как начать?",
    testimonials: "Отзывы",
    download: "Скачать приложение",
  },
};

const steps = [
  { img: step1, text: "Top Up your Wallet Balance" },
  { img: step2, text: "Scan the Bike's QR Code" },
  { img: step3, text: "Show Start QR to Inspector" },
  { img: step4, text: "Enjoy Your Ride" },
  { img: step5, text: "To End Your Ride Bring Bike to the Station" },
  { img: step6, text: "Click Stop Button in the App" },
  { img: step7, text: "Show Stop QR to Inspector" },
  { img: step8, text: "Done" },
];

const HomePage = () => {
  const [language, setLanguage] = useState("en");
  const [currentStep, setCurrentStep] = useState(0);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const handleNextStep = () => {
    setCurrentStep((prev) => (prev + 1) % steps.length);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prev) => (prev - 1 + steps.length) % steps.length);
  };

  return (
    <div style={styles.container}>
      <Navbar onLanguageChange={handleLanguageChange} /> 

      {/* Section Image */}
      <div style={styles.imageSection}>
        <img src={sectionImage} alt="Baku Bike Section" style={styles.sectionImage} />
      </div>

      {/* Horizontal Line */}
      <hr style={styles.horizontalLine} />

      {/* How to Get Started Section */}
      <section id="howTo" style={styles.howToSection}>
        <h2 style={styles.sectionTitle}>How to Use the App</h2>
        <h2 style={styles.subHeader}>{languages[language].howTo}</h2>
        <div style={styles.slider}>
          <button style={styles.arrowButtonLeft} onClick={handlePreviousStep}>
            &#10094;
          </button>
          <div style={styles.slide}>
            <div style={styles.imageContainer}>
              <img
                src={steps[currentStep].img}
                alt={`Step ${currentStep + 1}`}
                style={styles.stepImage}
              />
            </div>
            <p style={styles.stepText}>{steps[currentStep].text}</p>
          </div>
          <button style={styles.arrowButtonRight} onClick={handleNextStep}>
            &#10095;
          </button>
        </div>
      </section>

      <Footer /> 
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Gilroy, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    backgroundColor: "#ff0000",
    color: "#ffffff",
    minHeight: "100vh",
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    borderBottom: "1px solid #ffffff",
  },
  logo: {
    width: "40px",
  },
  navLinks: {
    display: "flex",
    gap: "20px",
  },
  navLink: {
    color: "#ffffff",
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "color 0.3s ease",
  },
  languageSelector: {
    display: "flex",
    gap: "10px",
  },
  languageButton: {
    backgroundColor: "#ffffff",
    color: "#ff0000",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
    fontSize: "14px",
    borderRadius: "5px",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  },
  imageSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionImage: {
    maxWidth: "100%",
  },
  horizontalLine: {
    border: "none",
    borderTop: "2px solid #ffffff",
    margin: "5px 0",
  },
  howToSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    backgroundColor: "#ffffff",
    padding: "20px",
    margin: '0 auto',
  },
  slider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
  },
  slide: {
    textAlign: "center",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "400px",
  },
  imageContainer: {
    width: "400px",
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  stepImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  stepText: {
    fontSize: "16px",
    color: "#ff0000",
    marginTop: "10px",
  },
  arrowButtonLeft: {
    backgroundColor: "transparent",
    border: "none",
    fontSize: "24px",
    color: "#ff0000",
    cursor: "pointer",
    position: "absolute",
  },
  arrowButtonRight: {
    backgroundColor: "transparent",
    border: "none",
    fontSize: "24px",
    color: "#ff0000",
    cursor: "pointer",
    position: "absolute",
    right: "0px",
  },
  footer: {
    padding: "20px",
    borderTop: "1px solid #ffffff",
    textAlign: "center",
    backgroundColor: "#ff0000",
  },
  footerLinks: {
    display: "flex",
    gap: "20px",
    justifyContent: "center",
    marginTop: "10px",
  },
  footerLink: {
    color: "#ffffff",
    textDecoration: "none",
    fontWeight: "bold",
    transition: "color 0.3s ease",
  },
  sectionTitle: {
    fontSize: "24px",
    color: "#ff0000",
    fontWeight: "bold",
    marginTop: "20px",
  },
};

export default HomePage;
