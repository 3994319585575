import React, { useEffect, useState } from "react";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "./AppStyles.css"; // Unified CSS for styling

const apiUrl = "https://bakubike.duckdns.org"; // Update with the actual backend URL

const QRCodeTable = () => {
    const [qrCodes, setQrCodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchQRCodes = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/admin/get-qrcodes`);
                const sortedQRCodes = response.data.sort((a, b) => {
                    const idA = parseInt(a.bike_id.replace("BB", ""), 10);
                    const idB = parseInt(b.bike_id.replace("BB", ""), 10);
                    return idA - idB;
                });
                setQrCodes(sortedQRCodes);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching QR codes:", err);
                setError("Failed to fetch QR codes. Please try again later.");
                setLoading(false);
            }
        };

        fetchQRCodes();
    }, []);

    // Function to download individual QR code
    const handleDownload = (qrCode, bikeId) => {
        const link = document.createElement('a');
        link.href = qrCode;
        link.download = `${bikeId}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Function to download all QR codes as a ZIP file
    const handleDownloadAll = async () => {
        const zip = new JSZip();
        const qrFolder = zip.folder("qr_codes");

        // Add each QR code as a PNG file to the zip folder
        qrCodes.forEach((qr) => {
            const qrBase64 = qr.qr_code.split(',')[1]; // Get the base64 data from the data URL
            qrFolder.file(`${qr.bike_id}.png`, qrBase64, { base64: true });
        });

        // Generate the zip and trigger download
        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, "QR_Codes.zip");
    };

    // Function to download all QR codes as a PDF with 3x3 cm dimensions
    const handleDownloadAllAsPDF = () => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'cm', // Set the unit to centimeters
            format: 'a4'
        });

        const pageWidth = 21; // A4 page width in cm
        const pageHeight = 29.7; // A4 page height in cm
        const qrCodeSize = 3; // Each QR code is 3x3 cm

        let x = 1; // Starting x position
        let y = 1; // Starting y position
        const margin = 1; // Margin between QR codes

        qrCodes.forEach((qr, index) => {
            // Convert base64 QR code to image and add it to the PDF
            const qrBase64 = qr.qr_code.split(',')[1]; // Get base64 image data
            const qrImage = `data:image/png;base64,${qrBase64}`;

            pdf.addImage(qrImage, 'PNG', x, y, qrCodeSize, qrCodeSize);
            pdf.text(`Bike ${qr.bike_id}`, x, y + qrCodeSize + 0.5); // Add bike ID below the QR code

            x += qrCodeSize + margin; // Move to the next column

            // If the x position exceeds the page width, move to the next row
            if (x + qrCodeSize > pageWidth) {
                x = 1; // Reset to the first column
                y += qrCodeSize + margin; // Move to the next row
            }

            // If the y position exceeds the page height, add a new page
            if (y + qrCodeSize > pageHeight) {
                pdf.addPage();
                x = 1; // Reset for new page
                y = 1; // Reset for new page
            }
        });

        pdf.save("QR_Codes.pdf"); // Download the PDF
    };

    if (loading) return <p>Loading QR codes...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="container">
            <h1 className="title">QR Codes for Bikes</h1>
            <button onClick={handleDownloadAll} className="download-button">
                Download All as ZIP
            </button>
            <button onClick={handleDownloadAllAsPDF} className="download-button">
                Download All as PDF
            </button>
            <table className="qr-code-table">
                <thead>
                    <tr>
                        <th>Bike ID</th>
                        <th>QR Code</th>
                        <th>Created At</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>
                    {qrCodes.map((qr) => (
                        <tr key={qr._id}>
                            <td>{qr.bike_id}</td>
                            <td>
                                <img src={qr.qr_code} alt={`QR Code for ${qr.bike_id}`} className="qr-code-image" />
                            </td>
                            <td>{new Date(qr.created_at).toLocaleString()}</td>
                            <td>
                                <button onClick={() => handleDownload(qr.qr_code, qr.bike_id)} className="download-button">
                                    Download
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default QRCodeTable;
